
import { Options, Vue } from "vue-class-component";

@Options({
  props: {
    showBg: { type: Boolean, default: true }
  }
})

export default class RegisterWarrantyBanner extends Vue {}
