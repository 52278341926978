
import { PropType } from '@vue/runtime-core';
import { Options, Vue } from 'vue-class-component';

interface ImageData {
    src: string;
    alt: string;
    heading: string;
    buttonText: string;
    headingClass: string;
    linkTo: object | string;
}

@Options({
  props: {
    images: { type: Array as PropType<Array<ImageData>> },
    uniqueId: { type: String, default: 'carouselBanner' }
  }
})
export default class CarouselBanner extends Vue {

}
