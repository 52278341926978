import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-3937a84a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "generic-banner" }
const _hoisted_2 = { class: "generic-banner-inner" }
const _hoisted_3 = { class: "generic-banner-item position-relative" }
const _hoisted_4 = ["src", "alt"]
const _hoisted_5 = ["innerHTML"]
const _hoisted_6 = ["innerHTML"]
const _hoisted_7 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("img", {
          src: `${require('@/assets/' + _ctx.image.src.replace(/^\//g, ''))}`,
          class: "d-block w-100",
          alt: _ctx.image.alt || ''
        }, null, 8, _hoisted_4),
        _createElementVNode("div", {
          class: _normalizeClass(["position-absolute", `generic-banner-heading ${_ctx.image.headingClass || 'top-right'}`])
        }, [
          (_ctx.image.heading && _ctx.isH1)
            ? (_openBlock(), _createElementBlock("h1", {
                key: 0,
                class: "text-uppercase mb-2 mb-lg-3 fw-normal",
                innerHTML: _ctx.image.heading
              }, null, 8, _hoisted_5))
            : (_ctx.image.heading && _ctx.isH2)
              ? (_openBlock(), _createElementBlock("h2", {
                  key: 1,
                  class: "text-uppercase mb-2 mb-lg-3 fw-normal",
                  innerHTML: _ctx.image.heading
                }, null, 8, _hoisted_6))
              : (_ctx.image.heading)
                ? (_openBlock(), _createElementBlock("div", {
                    key: 2,
                    class: "text-uppercase mb-2 mb-lg-3 h1 fw-normal",
                    innerHTML: _ctx.image.heading
                  }, null, 8, _hoisted_7))
                : _createCommentVNode("", true),
          (_ctx.image.buttonText && _ctx.image.linkTo)
            ? (_openBlock(), _createBlock(_component_router_link, {
                key: 3,
                to: _ctx.image.linkTo || '/',
                class: "text-decoration-none"
              }, {
                default: _withCtx(() => [
                  _createElementVNode("button", {
                    class: _normalizeClass(["btn px-3 px-lg-4 py-1 py-lg-2", _ctx.image.buttonClass || 'btn-primary'])
                  }, _toDisplayString(_ctx.image.buttonText), 3)
                ]),
                _: 1
              }, 8, ["to"]))
            : _createCommentVNode("", true)
        ], 2)
      ])
    ])
  ]))
}