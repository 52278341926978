
import { PropType } from '@vue/runtime-core';
import { Options, Vue } from 'vue-class-component';

interface ImageData {
    src: string;
    alt: string;
    heading: string;
    buttonText: string;
    headingClass: string;
    buttonClass: string;
    linkTo: object | string;
}

@Options({
  props: {
    image: { type: Object as PropType<ImageData> },
    isH2: { type: Boolean, default: false},
    isH1: { type: Boolean, default: false}
  }
})
export default class GenericBanner extends Vue {

}
