
import { mixins, Options, Vue } from "vue-class-component";
import CarouselBanner from "@/components/banners/CarouselBanner.vue";
import GenericBanner from "@/components/banners/GenericBanner.vue";
import RegisterWarrantyBanner from "@/components/banners/RegisterWarrantyBanner.vue";
import InfoSection from "@/components/misc/InfoSection.vue";
import { useMeta } from "vue-meta";
import { SetMetaData } from "@/mixins/utilities";

@Options({
  components: {
    CarouselBanner,
    InfoSection,
    GenericBanner,
    RegisterWarrantyBanner,
  },
})
export default class HomeView extends mixins(SetMetaData) {
  homeBannerImages = [
    {
      src: "home/home-page-header-banner-4.jpg",
      alt: "Berkart Product Range",
      heading: 'Live with <br class="d-block d-sm-none"/>Distinction',
      buttonText: "Shop Now",
      headingClass: "top-center w-100",
      linkTo: { name: "ProductRange" },
    },
    {
      src: "home/home-page-header-banner-1-updated.jpg",
      alt: "Berkart Vacuum Cleaner",
      heading: 'Versatile <br class="d-block d-sm-none"/>Cleaning',
      buttonText: "Shop Floorcare",
      headingClass: "",
      linkTo: { name: "ProductRange", params: { category: "floorcare" } },
    },
    {
      src: "home/home-page-header-banner-2.jpg",
      alt: "Berkart Blender and Air Fryer",
      heading: "Homemade <br/>Goodness",
      buttonText: "Shop Small Kitchen Appliances",
      headingClass: "center-left",
      linkTo: { name: "ProductRange", params: { category: "small-kitchen-appliances" } },
    },
    {
      src: "home/home-page-header-banner-3.jpg",
      alt: "Berkart Dripod",
      heading: "Portable & <br/>Space Saving",
      buttonText: "Shop Small Household Appliances",
      headingClass: "center-right",
      linkTo: { name: "ProductRange", params: { category: "small-household-appliances" } },
    },
  ];
  introSection = {
    imageSrc: "home/about-image-1.jpg",
    imageAlt: "Family enjoying time together - Berkart",
    imageLocation: "right",
  };
  genericBanners = {
    smallKitchenAppliances: {
      src: "home/home-page-banner-1.jpg",
      alt: "Berkart Small Kitchen Appliances",
      heading: 'Small Kitchen <br/>Appliances',
      buttonText: "Shop Now",
      headingClass: "center-right text-white drop-shadow",
      buttonClass: "btn-tertiary",
      linkTo: { name: "ProductRange", params: { category: "small-kitchen-appliances" } },
    },
    smallHouseholdAppliances: {
      src: "home/home-page-banner-2.jpg",
      alt: "Berkart Small Household Appliances",
      heading: 'Small Household <br />Appliances',
      buttonText: "Shop Now",
      headingClass: "center-left",
      linkTo: { name: "ProductRange", params: { category: "small-household-appliances" } },
    },
    floorcare: {
      src: "home/home-page-banner-3-updated.jpg",
      alt: "Berkart Floorcare",
      heading: 'Floorcare',
      buttonText: "Shop Now",
      headingClass: "center-right text-white drop-shadow",
      buttonClass: "btn-tertiary",
      linkTo: { name: "ProductRange", params: { category: "floorcare" } },
    },
  };

  created() {
    const { meta } = useMeta({});
    this.setMetaData(meta, {}, "", "Home | ");
  }
}
